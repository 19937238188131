<template>
	<div class="all-rule-list">
		
		<el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
			<el-form-item>
				<el-input style="width: 300px;" v-model="name" placeholder="输入工作室名称关键字查询" clearable>
					<el-button slot="append" icon="el-icon-search" @click="getDataList()"></el-button>
				</el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="dialogVisible=true" >添加</el-button>
				<!-- 				<el-button  type="primary"  @click="test()" >新增</el-button> -->
				<el-button type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0" >删除</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
			<el-table-column type="selection"  header-align="center" align="center" width="50">
			</el-table-column>
			<el-table-column prop="docterName" header-align="center" align="center" min-width="280" label="成员名称" >
			</el-table-column>
			<el-table-column prop="roleName"  min-width="200" header-align="center" align="center" label="工作室角色">
			</el-table-column>
			
			<el-table-column prop="phone" header-align="center" min-width="200" align="center" label="手机号">
			</el-table-column>
	
			<el-table-column prop="createTime" header-align="center" align="center" min-width="180" label="添加时间">
			</el-table-column>
			
			<el-table-column fixed="right" header-align="center" align="center" min-width="200" label="操作">
				<template slot-scope="scope">
					<el-button style="color: #0476F5;" type="text" size="small" @click="gotoEdit(scope.row)" >编辑</el-button>
			
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
		 :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
		</el-pagination>
		<el-dialog title="添加工作室成员" :visible.sync="dialogVisible" width="500px">
		<el-form label-width="100px">
			<el-form-item :inline="true" label="成员" required>
				<el-select style="width:300px" v-model="userId" multiple filterable>
					<el-option v-for="item in userList" :key="item.organBusiness.id" :value="item.organBusiness.id" :label="item.organBusiness.name+'('+item.organBusiness.phone+')'"></el-option>
				</el-select>
			</el-form-item>	
			<el-form-item label="角色分配" required>
				<el-select style="width:300px" v-model="roleIds" multiple  filterable>
					<el-option v-for="item in roleList" :key="item.id" :value="item.id" :label="item.name"></el-option>
				</el-select>
				
			</el-form-item>	
			
	
		</el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="addTeamMember" v-points>确定</el-button>
		    <el-button @click="dialogVisible = false">取消</el-button>
		  </div>
		</el-dialog>
		<el-dialog title="编辑工作室成员" :visible.sync="dialogVisible2" width="500px">
			<el-form label-width="100px">
			<el-form-item :inline="true" label="成员" required>
				<el-select style="width:300px" v-model="userId" multiple filterable>
					<el-option v-for="item in userList" :key="item.organBusiness.id" :value="item.organBusiness.id" :label="item.organBusiness.name+'('+item.organBusiness.phone+')'"></el-option>
				</el-select>
			</el-form-item>	
			<el-form-item label="角色分配" required>
				<el-select style="width:300px" v-model="roleIds" multiple  filterable>
					<el-option v-for="item in roleList" :key="item.id" :value="item.id" :label="item.name"></el-option>
				</el-select>
				
			</el-form-item>	
			
	
		</el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="editTeamMember" v-points>确定</el-button>
		    <el-button @click="dialogVisible2 = false">取消</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import Qs from 'qs'
	export default {
		data() {
			return {
				teamsName:'',
				entityId:'',
				userId:'',
				content:'',
				jcbzList:[],
				userList:[],
				roleIds:'',
				dataForm: {
				teamsName:'',
				entityId:'',
				content:'',
				
				},
				name: '',
				dataList: [],
				roleList:[],
				pageIndex: 1,
				pageSize: 10,
				totalPage: 0,
				dialogVisible: false,
				dialogVisible2: false,
				dataListLoading: false,
				dataListSelections: [],
				addOrUpdateVisible: false
			}
		},
		components: {
			// AddOrUpdate
		},
		created() {
			this.teamsName = this.$route.query.teamsName
			this.getDataList()
			this.getUserList()
			this.getRoleList()
			// this.getJcbzList()
		},
		computed: {


		},
		methods: {
			gotoEdit(row){
				this.dataForm =row


		this.dialogVisible2 = true
		
		this.userId =[row.docterId] 
		console.log(this.userId)
		this.roleIds = row.roleIds.split(',')
			},
			getRoleList(){
			
					let deptId = this.$cookie.get('hospitalId')
					// let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
					let obj = {
					
						pageNo: 1,
						pageSize: 999,
					
						roleType:2
					}
					this.dataListLoading = true
					this.$http({
						url: this.$http.adornUrl('/role/page'),
						method: 'get',
						params: obj,
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
				
							'token': this.$cookie.get('token'),
						},
					}).then(({
						data
					}) => {
						this.roleList = data.data;
					
					})
				
			},
			// 获取数据列表
			getDataList() {
		
				// let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
				let obj = {
				
					pageNo: this.pageIndex,
					pageSize: this.pageSize,
					docterName: this.name,
					teamsId:this.$route.query.id ,
				}
				this.dataListLoading = true
				this.$http({
					url: this.$http.adornUrl('/teamsMember/list'),
					method: 'get',
					params: obj,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',

						'token': this.$cookie.get('token'),
					},
				}).then(({
					data
				}) => {
					this.dataList = data.data;
					this.totalPage = data.totalCount

					this.dataListLoading = false
				})
			},
			getUserList(data){

					let hospitalId = this.$cookie.get('hospitalId')
					let deptId = ""
					if(!data){
						deptId = hospitalId
				
					}else if(!data.hasOwnProperty('deptId')){
						deptId = data.id
					}else{
						console.log('有deptId',data);
				
						deptId=data.deptId
				
					}
		
					this.dataListLoading = true
					this.$http({
						url: this.$http.adornUrl('/organBusiness/backUserList?pageSize=999&pageNo=1&deptId=' + deptId  ),
						method: 'get',
					}).then(async ({data}) => {
						if (data.status) {
								
							this.userList = data.data
						
						} else {
							this.userList = []
							
						}
						this.dataListLoading = false
					})
			},
			//基础病种
getJcbzList() {
				let deptId = this.$cookie.get('hospitalId')
				// let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
				let obj = {
				
					pageNo:1,
					pageSize: 9999,
					type:'基础病种'
				}
				this.dataListLoading = true
				this.$http({
					url: this.$http.adornUrl('/dict/list'),
					method: 'get',
					params: obj,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',

						'token': this.$cookie.get('token'),
					},
				}).then(({
					data
				}) => {
					this.jcbzList = data.data
				})
			},
			addTeamMember(){
				var that = this
				var params={
					teamsId:this.$route.query.id ,
					docterId:this.userId,
				
					roleIds:this.roleIds ,
					
					entityId:this.$route.query.entityId,
				}
				let arr = []
				this.userId.forEach(item=>{
					let param = {
					teamsId:this.$route.query.id ,
					docterId:item,
				
					roleIds:this.roleIds.join(',') ,
					
					entityId:this.$route.query.entityId,
				}
				arr.push(param)
				})
	
				that.$http({
				    url: that.$http.adornUrl('/teamsMember/add'),
				    method: 'post',
				    // data: {teamsMembers:JSON.stringify(arr)},
					data: JSON.stringify(arr),
					headers: {
						'Content-Type': 'application/json',
					
						'token': this.$cookie.get('token'),
					},
				  }).then(({
				    data
				  }) => {
				    if (data && data.status) {
				    that.$message({
				      message: '操作成功',
				      type: 'success',
				      duration: 1500,
				      onClose: () => {
				        that.dialogVisible = false
						that.userId=''
						that.roleIds=''
					
						that.getDataList()
				    
				      }
				    })
				  } else {
				    that.$message.error(data.msg)
				  }
				})
			},
			editTeamMember(){
				var that = this
				var params={
					teamsId:this.$route.query.id ,
					docterId:this.userId,
				
					roleIds:this.roleIds ,
					
					entityId:this.$route.query.entityId,
				}
				let arr = []
				this.userId.forEach(item=>{
					let param = {
					teamsId:this.$route.query.id ,
					docterId:item,
				
					roleIds:this.roleIds.join(',') ,
					
					entityId:this.$route.query.entityId,
				}
				arr.push(param)
				})
				
				that.$http({
				    url: that.$http.adornUrl('/teamsMember/update'),
				    method: 'post',
				    // data: {teamsMembers:JSON.stringify(arr)},
					data: JSON.stringify(arr),
					headers: {
						'Content-Type': 'application/json',
					
						'token': this.$cookie.get('token'),
					},
				  }).then(({
				    data
				  }) => {
				    if (data && data.status) {
				    that.$message({
				      message: '操作成功',
				      type: 'success',
				      duration: 1500,
				      onClose: () => {
				        that.dialogVisible = false
						that.userId=''
						that.roleIds=''
					
						that.getDataList()
				    
				      }
				    })
				  } else {
				    that.$message.error(data.msg)
				  }
				})
			},
			editTeam(){
				var that = this
				
				that.$http({
				    url: that.$http.adornUrl('/teamsManage/update'),
				    method: 'post',
				    params: that.dataForm
				  }).then(({
				    data
				  }) => {
				    if (data && data.status) {
				    that.$message({
				      message: '操作成功',
				      type: 'success',
				      duration: 1500,
				      onClose: () => {
				        that.dialogVisible2 = false
						
						that.getDataList()
				    
				      }
				    })
				  } else {
				    that.$message.error(data.msg)
				  }
				})
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val
			},
			
			
			
			gotoRoleInfo(info) {
				this.$router.push({
					path: '/role/role-info',
					query: {id: info.id}
				})
			},
			gotoRoleEdit(info) {
				this.$router.push({
					path: '/role/role-edit',
					query: {id: info.id}
				})
			},

			// 删除
			deleteHandle(id) {
				var userIds = id ? [id] : this.dataListSelections.map(item => {
					return item.id
				})
				var ids = {
					'userId': this.$cookie.get('userId'),
					ids: userIds.join(',')
				}

				this.$confirm(`确定进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: this.$http.adornUrl('/teamsMember/delete'),
						method: 'post',
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',

							'token': this.$cookie.get('token'),
						},
						data: Qs.stringify(ids)
					}).then(({
						data
					}) => {
						if (data.status) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.getDataList()
								}
							})
						} else {
							this.$message.error(data.msg)
							this.getDataList()
						}
					})
				}).catch(() => {})
			}
		}
	}
</script>
<style lang="scss">
	.all-rule-list {
		.search-top {
			padding-bottom: 20px;
			overflow: hidden;

			.search-btn {
				font-size: 20px;
				cursor: pointer;
			}
		}

		.el-dialog {
			width: 600px;
		}

	}
</style>
